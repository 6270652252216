import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import {
  ConfigurationServiceV1,
  CTAConfig,
  defineConfigurationService,
  VTPConfiguration,
} from '@oneaudi/vtp-configuration-service';
import { ContentService } from '@oneaudi/content-service';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'audi-content-service': ContentService;
  readonly 'vtp-configuration-service': ConfigurationServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      'gfa:locale-service': '^1.0.0',
      'vtp-configuration-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
    externals: {
      react: '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:server-request': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [defineConfigurationService()],

  create: ({ featureServices }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const configService = featureServices['vtp-configuration-service'];
    const contentService = featureServices['audi-content-service'];

    configService.setConfiguration(mapContent(contentService.getContent()));

    return {
      render: () => {
        return null;
      },
    };
  },
};

export default featureAppDefinition;

type KeyValue<Type> = { [key: string]: Type };

function mapContent(content: any): VTPConfiguration {
  // eslint-disable-next-line no-underscore-dangle
  if (content.__type === 'aem-headless') {
    const configuration: Partial<VTPConfiguration> = {};

    Object.keys(content.fields).forEach((fieldKey) => {
      if (fieldKey.startsWith('scopes_')) {
        const shortKey = fieldKey.replace('scopes_', '');
        if (!('scopes' in configuration)) {
          configuration.scopes = {} as any;
        }
        (configuration.scopes as unknown as KeyValue<Boolean>)[shortKey] = (
          content.fields as KeyValue<any>
        )[fieldKey] as boolean;
      } else if (fieldKey.startsWith('urls_')) {
        const shortKey = fieldKey.replace('urls_', '');
        if (!('urls' in configuration)) {
          configuration.urls = {} as any;
        }
        (configuration.urls as KeyValue<string>)[shortKey] = (content.fields as KeyValue<any>)[
          fieldKey
        ] as string;
      } else if (fieldKey.startsWith('scs_')) {
        const shortKey = fieldKey.replace('scs_', '');
        if (!('scs' in configuration)) {
          configuration.scs = {} as any;
        }
        (configuration.scs as KeyValue<string>)[shortKey] = (content.fields as KeyValue<any>)[
          fieldKey
        ] as string;
      } else if (fieldKey.startsWith('cta')) {
        configuration.cta = [];
        content.fields.cta.forEach((cta: any) => {
          const ctaEntry = {} as Partial<CTAConfig>;
          const ctaFields = cta.fields;
          Object.keys(ctaFields).forEach((ctaFieldKey) => {
            if (ctaFieldKey.startsWith('options_')) {
              ctaEntry.options = {} as any;
              // ctaEntry.options.apiKey = ctaFields.options_apiKey;
              ctaEntry.options!.replacePhone = ctaFields.options_replacePhone;
              ctaEntry.options!.filterByDealerId = {} as any;
              ctaEntry.options!.filterByDealerId!.filterList =
                ctaFields.options_filterByDealerId_filterList;
              ctaEntry.options!.filterByDealerId!.filterType =
                ctaFields.options_filterByDealerId_filterType;
            } else {
              (ctaEntry as KeyValue<any>)[ctaFieldKey] = (ctaFields as KeyValue<any>)[ctaFieldKey];
            }
          });
          configuration.cta!.push(ctaEntry as CTAConfig);
        });
      } else {
        (configuration as KeyValue<any>)[fieldKey] = (content.fields as KeyValue<any>)[fieldKey];
      }
    });

    return configuration as VTPConfiguration;
  }
  return content as VTPConfiguration;
}
